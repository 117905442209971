@import "partials/app/_brick-portlet.scss";
@import "partials/app/_image-upload.scss";

.refresh-btn {
    margin-right: 10px;
    float: left;
}

.portlet > .portlet-title > .actions .btn {
    padding: 4px 10px;
    font-size: 13px;
    line-height: 1.5;
}

.public-url {
    margin-right: 10px;
}

.form-row {
    margin-bottom: 35px;
}

.page-header.navbar .page-logo .logo-default {
    margin-top: 13px;
}