.brick-portlet {
    .content-cards {

        .content-card {

            .content {
                display: block;
                width: 100%;
                padding: 7px;
                border: 1px solid #D8D8D8;
                border-radius: 6px;
                background: #FFF;

                * {
                    margin: 0;
                    overflow: hidden;
                }

                h4 {
                    height: 32px;
                    margin-bottom: 2px;
                    font-size: 14px;
                    font-weight: bold;
                }

                p {
                    height: 50px;
                    margin-bottom: 6px;
                    font-size: 10px;
                    line-height: 10px;

                    &.empty {
                        padding-top: 20px;
                        font-size: 14px;
                    }
                }

                video {
                    margin-top: 5px;
                }

                .empty {
                    font-style: italic;
                    color: #d2d2d2;
                    text-decoration: line-through;
                }

                .img {
                    height: 100px;
                    width: 100%;

                    &.empty {
                        border: 1px solid #d2d2d2;
                        line-height: 100px;
                        text-align: center;

                        &::before {
                            content: 'no image';
                        }
                    }
                }

            }

            &.calendar-card .content {
                h4 {
                    height: auto;
                    margin-bottom: 10px;
                }

                span {
                    display: block;
                    width: 100%;
                    margin-bottom: 3px;

                    strong {
                        display: block;
                        width: 100%;
                        font-size: 12px;
                    }
                }
            }

        }
    }

    h3.no-content {
        margin-bottom: 20px;
    }
}