.image-upload {

    a {
        display: block;
        margin-top: 10px;
    }

    .current-image {
        margin-bottom: 35px;

        button {
            margin-top: 10px;
        }

        img {
            max-width: 100%;
            max-height: 250px;
            margin-right: 10px;
        }
    }
}